import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'gatsby';

const BlogItem = ({ blog }) => {
  const { title, image, createdAt, author, shortDescription, slug } = blog;

  return (
    <Row className="mt-3 mb-3">
      <Col lg={4}>
        <img src={image.fixed.src} alt={title} className="d-block mx-auto" />
      </Col>
      <Col lg={8}>
        <h1>{title}</h1>
        <p>
          {createdAt} - {author.name}
        </p>
        <p>{shortDescription.shortDescription}</p>
        <Link to={`/blog/${slug}`}>
          <Button variant="outline-success">Lees meer</Button>
        </Link>
      </Col>
    </Row>
  );
};

export default BlogItem;
