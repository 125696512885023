import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HeroImage from '../components/HeroImage';
import BlogList from '../components/blog/BlogList';

export const query = graphql`
  query {
    backgroundImg: file(relativePath: { eq: "background-table.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const blog = ({ data }) => {
  return (
    <Layout pageInfo={{ pageName: 'blog' }}>
      <SEO title="Blog" />
      <HeroImage img={data.backgroundImg.childImageSharp.fluid}>
        <h1 className="text-white">Blog</h1>
      </HeroImage>
      <Container>
        <BlogList />
      </Container>
    </Layout>
  );
};

export default blog;
