import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BlogItem from './BlogItem';

const getBlogPosts = graphql`
  query {
    blogPosts: allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          slug
          createdAt(formatString: "DD.MM.YYYY")
          author {
            name
          }
          image {
            fixed(width: 250, height: 250) {
              src
            }
          }
          shortDescription {
            shortDescription
          }
        }
      }
    }
  }
`;

const BlogList = () => {
  const { blogPosts } = useStaticQuery(getBlogPosts);

  return (
    <>
      {blogPosts ? (
        blogPosts.edges.map(({ node }, index) => {
          return <BlogItem blog={node} key={index} />;
        })
      ) : (
        <p>Er zijn momenteel geen blogpsots. Kom later terug.</p>
      )}
    </>
  );
};

export default BlogList;
